<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table custom-table-padding">
        <thead>

        <tr>
          <th class="patient-details">Patient Details</th>
          <th class="admission-no" v-if="prescriptionType === 'IPD'">
            <span class="d-block">Admission No |</span>
            <span class="d-block">Date & Time</span>
          </th>
          <th v-if="prescriptionType === 'IPD'">Ward/Bed/Cabin</th>
          <th>
            <span class="d-block">Consultant</span>
            <span v-if="prescriptionType === 'IPD'">(Referred doctor)</span>
          </th>
          <th v-if="prescriptionType === 'IPD'">Admitted By</th>
          <th class="action-th">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>
            <div class="col">
              <strong>
                <h4>{{ item.patient_name }} ({{ item.age }} years, {{ item.gender }}) </h4>
              </strong>
              {{ item.mobile }} | {{ item.patient_id }}
            </div>
          </td>

          <td v-if="prescriptionType === 'IPD'" >
            <div class="col">
              <h5 class="text-bold">{{ item.admission_no }}</h5>
              <span>{{ item.admission_date }}</span>
            </div>
          </td>

          <td v-if="prescriptionType === 'IPD'" >
            {{ item.word_no }}
          </td>

          <td>
            {{ prescriptionType === 'OPD' ? item.opd_consultant : item.ipd_consultant }}
          </td>

          <td v-if="prescriptionType === 'IPD'">
            {{ item.admitted_by }}
          </td>

          <td>
            <div class="action">
              <button @click="goToDoctorOrder(item.id)" class="btn btn-primary btn-sm">
                <i class="fas fa-plus"></i>
                Bill
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <Paginations :offset="offset" :state-name="stateName"/>
      </div>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import Paginations from "@/components/atom/Pagination.vue";

const $route = useRoute();
const $router = useRouter();

const props = defineProps({
  tableItems: {
    type: Array,
  },
  stateName: {
    type: String,
    default: "paginationData"
  },
  routeName: {
    type: String,
    default: "invoice-pharmacy"
  },
  prescriptionType: {
    type: String,
    default: "IPD"
  },
  offset: {
    type: Number,
    default: 10
  }
})
const goToDoctorOrder = (orderId) => {
  const params = {
    ...$route.params,
    orderId
  }

  $router.push({
    name: props.routeName,
    params,
    query: $route.query

  })
}
</script>

<style scoped>

.admission-no {
  width: 15%;
}

.patient-details {
  width: 25%;
}

.print-btn {
  margin-right: 5px;
}

.action-th {
  width: 15%;
  text-align: center;
}

.action {
  text-align: center;
}

.action .print {
  margin-right: 4px;
}

.profile-pic {
  width: 65px;
}
</style>